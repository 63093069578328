.checkbox{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.labelText{
    font-size: 12px;
    font-weight: bold;
    color: #119fe3;
    text-transform: uppercase;
}