#activation ion-content {
  padding: 20px;
}
#activation ion-grid {
  align-items: center;
}

#activation ion-input input {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
}

#activation ion-input input:focus {
  border: 1px solid var(--ion-color-primary);
  box-shadow: 0 0 2px var(--ion-color-primary);
}

#activation .slider-intro {
  padding: 24px;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#activation ion-img {
  margin-bottom: 20px;
  width: 192px;
}

#activation h1 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

#activation p {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

#error {
  text-align: center;
  color: red;
  padding-bottom: 10px;
}

.versao {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  font-size: 10px;
}