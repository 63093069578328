html {
  --ion-default-font: 'Montserrat', sans-serif;
}
ion-content {
  --background: var(--ion-color-background);
}
.tab-selected {
  background-color: #eee;
}

ion-card ion-card-header ion-card-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

ion-list,
ion-item {
  --ion-background-color: transparent;
}
ion-item .item-native {
  padding: 0 0 5px 0 !important;
}

:root,
:root[mode='md'],
:root[mode='ios'] {
  --ion-font-family: 'Lato', sans-serif;
  font-family: var(--ion-font-family) !important;
}

.page {
  margin-top: 86px;
}
.card {
  margin-bottom: 20px;
}
.card {
  background-color: var(--ion-color-light);
}

.card ion-card-content {
  background: transparent !important;
}

.card__light {
  background-color: var(--ion-color-light);
  --box-shadow: 1px 3px 9px var(--ion-color-light);
}

.card__primary {
  background-color: var(--ion-color-primary);
  --box-shadow: 1px 3px 9px var(--ion-color-primary);
}

.card__secondary {
  background-color: var(--ion-color-secondary);
  --box-shadow: 1px 3px 9px var(--ion-color-secondary);
}

.card__tertiary {
  background-color: var(--ion-color-tertiary);
  --box-shadow: 1px 3px 9px var(--ion-color-tertiary);
}

.card__quaternary {
  background-color: var(--ion-color-success-shade);
  --box-shadow: 1px 3px 9px var(--ion-color-success-shade);
}

.card__quintenary {
  background-color: var(--ion-color-primary-shade);
  --box-shadow: 1px 3px 9px var(--ion-color-primary-shade);
}

.card__success {
  background-color: var(--ion-color-success);
  --box-shadow: 1px 3px 9px var(--ion-color-success);
}

.card__danger {
  background-color: var(--ion-color-danger);
  --box-shadow: 1px 3px 9px var(--ion-color-danger);
}

.card__dark {
  background-color: var(--ion-color-dark);
  --box-shadow: 1px 3px 9px var(--ion-color-dark);
}

.card__primary ion-card-header ion-card-title,
.card__secondary ion-card-header ion-card-title,
.card__tertiary ion-card-header ion-card-title,
.card__quaternary ion-card-header ion-card-title,
.card__quintenary ion-card-header ion-card-title,
.card__success ion-card-header ion-card-title,
.card__danger ion-card-header ion-card-title,
.card__dark ion-card-header ion-card-title {
  color: #fff;
}
.card__light ion-label {
  color: #999;
}

/* Hightchart */
.highcharts-container .chart-tip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.highcharts-container .highcharts-tooltip .title {
  color: #546e7a;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px;
  text-transform: uppercase;
  display: block;
}

.highcharts-container .highcharts-tooltip .content {
  color: #37474f;
  line-height: 16px;
  font-size: 14px;
}

.highcharts-container rect.highcharts-background {
  fill: transparent !important;
}

.highcharts-container rect {
  stroke: transparent !important;
}

.highcharts-legend-item {
  color: #fff;
}
